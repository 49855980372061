import React from 'react'

const UsercentricsContainer: React.FC<{ settingsID?: string }> = ({ settingsID, children }) => {
    React.useEffect(() => {
        if (settingsID && !document.getElementById('usercentrics-cmp')) {
            const script = document.createElement('script') as HTMLScriptElement & { 'data-version': string }
            /* 
            
            data-version attribute controls version of the usercentrics to display
            
            preview version (called draft version in UC conifguration dashboard) is used for development and stage environments 

            */
            if (
                process.env.NODE_ENV === 'development' ||
                window.location.pathname.includes('localhost') ||
                window.location.pathname.includes('stage')
            ) {
                script.setAttribute('data-version', 'preview')
            }
            script.id = 'usercentrics-cmp'
            script.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js'
            script.async = true
            script.setAttribute('data-settings-id', settingsID)
            document.head.appendChild(script)
            return () => {
                document.head.removeChild(script)
            }
        }
    }, [settingsID])

    // This is a workaround for the usercentrics button not being accessible for style overrides
    // As it is present within a shadow dom, we need to wait for it to be rendered and then set the part attribute
    React.useEffect(() => {
        let interval = setInterval(() => {
            if (document.querySelector('#usercentrics-root')?.shadowRoot?.querySelector('div > div > button')) {
                document
                    .querySelector('#usercentrics-root')
                    ?.shadowRoot?.querySelector('div > div > button')
                    ?.setAttribute('part', 'privacy_button')
                clearInterval(interval)
            }
        }, 200)
    }, [])

    return <>{children}</>
}

export default UsercentricsContainer
